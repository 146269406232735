import { default as localeRu } from "./locales/ru.js";

export default {
  compilation: {
    strictMessage: false,
  },
  defaultLocale: "ru",
  detectBrowserLanguage: {
    cookieKey: "donut-locale",
    redirectOn: "root",
    useCookie: true,
  },
  locales: [
    {
      code: "ru",
      name: "Russian",
    },
  ],
  messages: {
    ru: localeRu,
  },
  strategy: "no_prefix",
};
